import { graphql as gql, PageProps } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import useStoryblok from "../lib/storyblok"
import useStoryblokComponent from "../lib/useStoryblokComponent"

const PrivacyPolicy: React.FC<PageProps<any>> = ({ data, location }) => {
  const story = useStoryblok(data.storyblokEntry, location)

  const StoryblokComponents = useStoryblokComponent(story)
  return (
    <Layout>
      {" "}
      <Seo title="Privacy Policy" />
      {StoryblokComponents}
    </Layout>
  )
}

export default PrivacyPolicy

export const query = gql`
  query PrivacyPolicy {
    storyblokEntry(full_slug: { eq: "privacy-policy" }) {
      name
      content
    }
  }
`
